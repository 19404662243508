<script>

export default {
  name: "HistoryRateSearch.vue",
  props: {
    histories: {
      type: [Array, Object],
    }
  },
  
  data() {
    return {
      selectedItem: 1,
    }
  },
  
  methods: {
    getAlarmClasses(item) {
      let stringOfAlarm = ''
      for (let i = 0; i < item.alarm_class.length; i++){
        
        if (item.alarm_class[i].name !== null) {
          if (i === 0) {
            stringOfAlarm = '- '
          }
          
          stringOfAlarm += item.alarm_class[i].name
          
          if (i < item.alarm_class.length - 1) {
            stringOfAlarm += ', ';
          }
        }
      }
      
      return stringOfAlarm;
    }
  }
}

</script>

<template>
  <div class="history">
    <div
      class="history__item"
      v-ripple
      v-for="(item, index) in histories"
      :key="index"
      @click="$emit('search', index)"
    >
      <v-icon size="20px">mdi-history</v-icon>
      <span>
        {{item.places_from[0].name}} - {{item.places_to[0].name}} - {{item.unit_type[0].name}} {{getAlarmClasses(item)}}
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.history {
  border-radius: 3px;
  background: #FFF;
  width: auto;
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: visible;
  box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.28);
  
  &__item {
    display: block;
    width: 100%;
    padding: 6px 8px 6px 10px;
    cursor: pointer;
    
    &:first-child {
      border-radius: 4px 4px 0 0 ;
    }
    
    &:last-child {
      border-radius: 0 0 4px 4px;
    }
    
    &:hover {
      background: #E9E9E9;
    }
    
    > span {
      margin-left: 6px;
      font-size: 14px;
    }
  }
}

ul {
  list-style-type: none;
}

</style>
