<script>
export default {
  props: {
    value: Boolean,
    label: String,
  },

  computed: {
    isChecked: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },

  methods: {
    toggleCheckbox() {
      this.isChecked = !this.isChecked;
    },
  },
};
</script>

<template>
  <div class="checkbox" @click="toggleCheckbox">
    <div class="checkbox__wrapper">
      <div class="checkbox__box" :class="{ checked: isChecked }">
        <svg class="checkbox__box-img" v-if="isChecked" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 4L4 7L9 1" stroke="#434F5B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
    <span class="checkbox__label">{{ label }}</span>
  </div>
</template>

<style scoped lang="scss">
.checkbox {
  display: flex;
  cursor: pointer;
  align-items: flex-start;

  &__wrapper {
    flex-shrink: 0;
  }

  &__box {
    width: 16px !important;
    height: 16px;
    top: 2px;
    margin-right: 8px;
    border: 2px solid var(--grey-50);
    border-radius: 2px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;

    &.checked {
      border: 2px solid var(--grey-70);
      width: 16px  !important;
      height: 16px;
    }

    &-img {
      width: 10px;
      height: 8px;
      //top: -8px;
      position: relative;
    }
  }

  &__label {
    color: var(--grey-70);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    text-align: left;
  }
}
</style>
