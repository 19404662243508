<script>
import {bus} from "@/router/bus";

export default {
  name: 'AlternativeRoute',
  
  props: {
    isDirectAlternative: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data() {
    return {
      place_from: [],
      place_to: [],
      places_to: [],
      on_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      alt_places_from:[],
      alt_places_to:[],
      alt_place_from: [],
      place_to_name: '',
      place_from_name: '',
      unit_code: [],
      client_id: null,
      alarm_class: [],
      alarm_classes: [],
      method: '',
      type: 0,
    }
  },
  
  // watch: {
  //   isDirectAlternative(value) {
  //     console.log(value)
  //   }
  // },
  // watch: {
  //   isDirectAlternative(value) {
  //     console.log(value)
  //   }
  // },
  mounted() {
    bus.$on("getalternativeroute", (data) => {
      this.setSendingData(data);
    });
    this.cid = this.$_getsetting('client_id') ? this.$_getsetting('client_id') : -99;
  },
  
  methods: {
    setSendingData(data) {
      this.place_from_name = data.place_from_name
      this.place_to_name = data.place_to_name
      this.type = data.type
      this.method = data.method;
      let out_date = new Date(data.on_date);
      out_date.setDate(out_date.getDate());
      var sending_data = {}
      sending_data = {
        place_from: data.place_from,
        place_to: data.place_to,
        type: 1,
        method: this.$getters.getAlternativeRoute.name,
      }
      if (data.is_alt_to == 1) {
        this.getAlternativeRoute(sending_data, data)
      }
      sending_data = {
        place_from: data.place_from,
        place_to: data.place_to,
        type: 0,
        method: this.$getters.getAlternativeRoute.name,
      }
      this.getAlternativeRoute(sending_data, data)
    },
  
    getAlternativeRoute(sending_data, data) {
      if (data.method == 'ratelist') {
        this.$postapi(this.$address + this.$getters.getAlternativeRoute.uri, sending_data).then(data => {
          if (data.error == 0) {
            if (sending_data.type == 0) {
              this.alt_places_from = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
            } else {
              this.alt_places_to = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
            }
            bus.$emit('get_next_period')
          } else {
            bus.$emit("show_notify", {
              color: "error",
              notifytext: data.message
            });
          }
        });
      } else {
        this.$postapi(this.$address + this.$getters.getAlternativeRoute.uri, {
          method: this.$getters.getAlternativeRoute.name,
          place_to: data.place_to,
          place_from: data.place_from,
          unit_code: data.unit_code,
          client_id: data.client_id != null ? data.client_id : -99,
          alarm_class: data.alarm_class,
          type: sending_data.type,
        }).then(data => {
          if (data.error == 0) {
            if (sending_data.type == 0) {
              this.alt_places_from = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
            } else {
              this.alt_places_to = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
            }
            
            bus.$emit('get_next_period')
          } else {
            bus.$emit("show_notify", {
              color: "error",
              notifytext: data.message
            });
          }
        });
        this.alarm_class = data.alarm_class;
      }
  
      this.place_to = data.place_to;
      this.place_from = data.place_from;
      this.on_date = data.on_date;
      this.unit_code = data.unit_code;
      this.client_id = data.client_id != null ? data.client_id : -99 ;
    },

    searchAlternativeRouteFrom(id, name) {
      this.place_from = id
      this.place_from_name = name
      setTimeout(this.sendRequest(), 500);
      bus.$emit('changeplacefrom', this.place_from);
      bus.$emit('changealertplacefrom', this.place_from);
    },
  
    searchAlternativeRouteTo(id, name) {
      this.place_to = id;
      this.place_to_name = name
      setTimeout(this.sendRequest(), 500);
      bus.$emit('changeplaceto', this.place_to);
      bus.$emit('changealertplaceto', this.place_to);
    },

    sendRequest() {
      if (this.method == 'ratelist') {
        bus.$emit("getratelistAlternative", {
          place_from: this.place_from,
          place_to: this.place_to,
          unit_code: this.unit_code,
          on_date: this.on_date,
          place_to_name: this.place_to_name,
          place_from_name: this.place_from_name,
          client_id: this.$_getsetting('client_id') ? this.$_getsetting('client_id') : -99,
        });
      } else {
        bus.$emit("getratealarmlist", {
          place_from: this.place_from,
          place_to: this.place_to,
          unit_code: this.unit_code,
          on_date: this.on_date,
          alarm_class: this.alarm_class,
          place_to_name: this.place_to_name,
          place_from_name: this.place_from_name,
          client_id: this.$_getsetting('client_id') ? this.$_getsetting('client_id') : -99,
        });
      }
    },

    checkParameters(data){
      return (data.on_date != null && data.place_to.length > 0 && data.place_from.length > 0 && data.unit_code.length > 0 && data.client_id != null) ? true : false
    }
  },
}
</script>

<template>
  <div class="alternative-route">
    <div class="alternative-route-info">
      <p class="alternative-route-info-title">Альтернативные маршруты</p>
      <div
        class="alternative-route-row"
        v-for="(item, index) in alt_places_from"
        :key="index"
      >
        <v-btn
          v-if="(isDirectAlternative == true && item.is_direct == 1)
          || (item.is_direct == 0 && isDirectAlternative == false)"
          depressed
          dark
          rounded
          color="var(--main-orange)"
          class="my-1"
          outlined
          small
          type="submit"
          @click="searchAlternativeRouteFrom(String(item.id), item.name)"
           >
          <div class="alternative-route-alt_place_from">
            {{item.name}} - {{place_to_name}}
          </div>
        </v-btn>
      </div>
  
      <div
        class="alternative-route-row"
        v-for="item in alt_places_to"
        :key="item.id"
      >
        <v-btn
          v-if="(isDirectAlternative == true && item.is_direct == 1)
          || (item.is_direct == 0 && isDirectAlternative == false)"
          depressed
          dark
          rounded
          color="var(--main-orange)"
          class="my-1"
          outlined
          small
          type="submit"
          @click="searchAlternativeRouteTo(String(item.id), item.name)"
           >
          <div class="alternative-route-alt_place_from">
            {{place_from_name}} - {{item.name}}
          </div>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<style scoped>
.alternative-route{
  width: 100%;
  min-height: 10vw;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #e6eae9;
  padding: 4.93%;
}

.alternative-route-info-title{
  font-size: 11pt;
  line-height: 15px;
  margin-top: 2px;
  text-transform: none;
  margin-left: 1.61%;
}

.alternative-route-row {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
}

.alternative-route-alt_place_from{
  text-transform: none;
}

</style>
