<script>
export default {
  name: 'NewsCard',
  props: {
    news: {
      type: [Array, Object]
    }
  },
  
  data () {
    return {
    }
  },
  
  methods: {

  }
}
</script>

<template>
  <div class="quote-card">
    <router-link to="/DirectorMessage">
      
      <div class="quote-card__quotes">
        <svg xmlns="http://www.w3.org/2000/svg" width="104" height="76" viewBox="0 0 104 76" fill="none">
          <path d="M66.4277 75.8383H88.4277L103.094 45.7411V0.595215H59.0944V45.7411H81.0944M7.76107 75.8383H29.7611L44.4277 45.7411V0.595215H0.427734V45.7411H22.4277L7.76107 75.8383Z" fill="#F0A030"/>
        </svg>
      </div>
      
      <div class="quote-card__name">Сергей Ковганко</div>
      <div class="quote-card__post">основатель и руководитель компании Vedexx</div>
      
      <blockquote class="quote-card__text">Наша задача - с&nbsp;помощью современных технологий сделать логистику простой и понятной.</blockquote>
    
      <div class="quote-card__photo">
        <img src="../../assets/img/kovganko.png">
      </div>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.quote-card {
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  width: 100%;
  height: auto;
  min-height: 530px;
  transition-duration: 0.5s;
  cursor: pointer;
  padding-bottom: $size-20;
  
  &:hover {
    transform: translateY(-2px) scale(1.01);
  }
  
  &__quotes {
    position: relative;
    margin: auto;
    width: 104px;
    top: -30px;
  }
  
  &__name {
    font-weight: 600;
    font-size: 20px;
    line-height: 21px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;
  }
  
  &__post {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.25px;
    text-transform: lowercase;
    margin: 10px 40px 0;
  }
  
  &__text {
    font-weight: 400;
    font-size: 26px;
    line-height: 33px;
    margin: 20px auto 0 auto;
    max-width: 290px;
    text-align: center;
    letter-spacing: 0.25px;
    color: #535353;
  }
  
  &__photo {
    width: 130px;
    margin: $size-16 auto;
    left: 0;
    right: 0;
    text-align: center;
    height: 130px;
    position: relative;
    
    > img {
      border-radius: 10px;
      width: 130px;
      height: 130px;
    }
  }
}

a {
  text-decoration: none !important;
  color: black !important;
}
</style>
