<script>
export default {
  name: 'SlideRateCard',
  props: {
    rate: {
      type: [Array, Object]
    },
    port_from_id: {
      type: Number
    }
  },
  
  data () {
    return {
      showLoader: false,
      price: [],
      on_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }
  },
  
  mounted() {
    this.getRatePrice()
  },
  
  methods: {
    
    getRatePrice() {
      this.showLoader = true
      this.$postapi(this.$address + this.$getters.getMinRateJson.uri, {
        method: this.$getters.getMinRateJson.name,
        on_date: this.on_date,
        client_id: -99,
        place_to: this.rate.place_to_id,
        place_from: this.port_from_id,
        unit_code: 6,
      }).then((data) => {
        if (data.error == 0) {
          this.price = JSON.parse(data.message)
          this.showLoader = false
        }
      });
    },
    
    openRateView(){
      let routeData = this.$router.resolve({ path: "/RatePage", query : { key: this.price.key_orig, on_date: this.on_date, cid: this.$_getsetting("client_id") } });
      window.open(routeData.href, '_blank');
    },
  }
}
</script>

<template>
  <div
    class="slider-card"
    @click="openRateView"
  >
    <div>
      <div class="slider-card__content__place">
        {{rate.place}}
      </div>
      
      <v-progress-circular
        v-if="showLoader === true"
        :width="3"
        color="white"
        indeterminate
      ></v-progress-circular>
      
      <p class="slider-card__content__price" v-else>
       {{parseFloat(price.total).toLocaleString('ru-RU')}} р
      </p>
    </div>
    
    <div
      class="slider-card__content__link"
    >
      подробнее
      <img
        class="slider-card__content__link-img mt-1"
        src="../../../assets/img/arrow-right-thin.png"
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
.slider-card {
  width: 100%;
  height: 100%;
  padding: 0 $size-16;
  background: rgba(111, 150, 170, 0.65);
  border-style: solid;
  border-color: #436272;
  border-width: 0px 8px 4.22808px 0px;
  box-shadow: 2px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-weight: 800;
  position: relative;
  color: #FFFFFF;
  letter-spacing: 0.01em;
  cursor: pointer;
  transition-duration: 0.5s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media (min-width: 1000px) {
    &:hover {
      margin-top: 1px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.1);
      transform: translateY(-2px) scale(1.001);
    }
  }
  
  &__content {
    display: flex;
    height: auto;
    width: 100%;
    
    &__place {
      font-size: $size-16;
      line-height: 23px;
    }
    
    &__price {
      color: #FFFFFF;
      box-decoration-break: clone;
      background: var(--main-orange);
      padding: 1px 2px;
      display: inline;
      line-height: 1.8;
      box-shadow: 0.250px 0 0 var(--main-orange),-0.250px 0 0 var(--main-orange);
      font-size: 20px;
      z-index: 12;
      position: relative;
      margin-top: -18px;
    }
    
    &__link {
      font-size: 13px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      text-align: center;
      line-height: 15px;
      cursor: pointer;
      z-index: 25;
      
      &-img {
        display: flex;
        align-items: flex-end;
        position: relative;
        margin-top: 4px;
        width: 20px;
        height: 15px;
      }
    }
  }
}

a {
  text-decoration: none;
  color: white !important;
}
</style>
