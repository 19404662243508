<script>
import {bus} from "../../router/bus";

export default {
  name: 'FaqBlock',
  components: {
  },
  
  data() {
    return {
      questions: [],
      firsQuestionsIDs: [5, 1, 18, 8, 9],
      displayedQuestionCount: 4,
    }
  },
  
  mounted() {
    this.getFaqs()
  },
  
  methods: {
    getFaqs() {
      this.$postapi(this.$address + this.$getters.getVedexxFaq.uri, {
        method: this.$getters.getVedexxFaq.name,
      }).then(data => {
        if (data.error === 0) {
          this.questions.push(JSON.parse(data.message))
          this.sortQuestions()
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    sortQuestions() {
      for (let i = 0; i < this.firsQuestionsIDs.length; i++) {
        let index = this.questions[0].findIndex(el => el.id == this.firsQuestionsIDs[i]);
        
        // меняю местами вопросы - ставлю на первое место те, чьи ID перечислены
        this.questions[0].splice(i, 0, this.questions[0].splice(index, 1)[0]);
      }
      this.questions[0].pop()
    },
    
    showMore() {
      if (this.questions[0] && this.questions[0].length > this.displayedQuestionCount) {
        this.displayedQuestionCount += 4;
      }
    },
  }
}
</script>

<template>
  <div class="faq-block">
    <v-expansion-panels v-if="questions.length > 0" accordion>
      <transition-group name="list" tag="div" class="faq-block__item" style="width: 110%">
      <v-expansion-panel
        v-for="question in questions[0].slice(0, displayedQuestionCount)"
        :key="question.id"
      >
        <v-expansion-panel-header color="faq-block__panel" hide-actions>
          <template v-slot:default="{ open }">
            <div class="faq-block__panel-question">{{question.question}}</div>
              <v-icon size="30" color="var(--main-orange)">
                <template v-if="open">mdi-minus-circle</template>
                <template v-else>mdi-plus-circle</template>
              </v-icon>
          </template>
        </v-expansion-panel-header>
        
        <v-expansion-panel-content>
          <div class="faq-block__panel-answer">{{question.answer}}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
      </transition-group>
    </v-expansion-panels>
    
    <div
      v-if="questions[0] && questions[0].length > displayedQuestionCount"
      @click="showMore"
      class=" faq-block__show-more"
    >
      Показать больше
    </div>
  </div>
</template>

<style scoped lang="scss">

.list-enter-active, .list-leave-active {
  transition: all 0.7s;
}

.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}

.faq-block {
  margin: $size-20 0;
  display: flex;
  flex-direction: column;
  
  &__item {
    width: 110% !important;
  }
  
  &__panel {
    height: 90px;
    
    &-question {
      width: 90%;
      font-size: 24px;
      font-weight: 600;
      padding-left: 10px;
      
      @media (max-width: $tablet-width) {
        font-size: 20px;
      }
    }
    
    &-answer {
      width: 80%;
      font-size: 18px;
      padding-left: 10px;
      padding-bottom: 20px;
    }
  }
  
  &__show-more {
    font-size: 14px;
    margin-top: $size-24;
    display: flex;
    width: 100%;
    font-weight: 800;
    color: black;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    z-index: 2;
    @include right-gap;
    
    &:hover {
      text-decoration: underline;
    }
    
    &:after {
      content: url('https://api.iconify.design/iconamoon/arrow-down-2-duotone.svg?width=20&height=20');
      text-align: center;
      line-height: 10px;
    }
  }
}
</style>
