<script>
import FindRatePanel from "../common/FindRatePanel";
import InfoSlider from "./info-slider/InfoSlider.vue";
import RateListNew from "@/components/rate-list/RateListNew.vue";
import FindComplexRatePanel from "../common/FindComplexRatePanel";
import FIndDangerRatePanel from "../common/FIndDangerRatePanel";
import RateComplexList from "../complex-rate/RateComplexList";
import InfoNewsBlock from "./InfoNewsBlock";
import MainInfoBlock from "./MainInfoBlock";

import {bus} from "@/router/bus";

export default {
  name: "MainPage.vue",
  components : {
    FindRatePanel,
    InfoSlider,
    // InfoSliderMobile,
    // RateList,
    RateListNew,
    FIndDangerRatePanel,
    RateComplexList,
    InfoNewsBlock,
    MainInfoBlock,
    FindComplexRatePanel,
  },

  data: () => ({
    tab: null,
    services: [],
    searchOneRateClicked: false,
    searchComplexRateClicked: false,
    searchAlarmRateClicked: false,
    cId: null,

    windowWidth: window.innerWidth,

    slides: [
      {
        id: 1,
        title: "",
        description: "",
        bg_img: "bg_slide1.webp",
        href: "1",
        style_options: "margin-left: 60%",
        man_img: 'laptop_slide_man.png'
      },

      {
        id: 3,
        title: "Сначала Vedexx платит за вас\n" +
          "Затем вы оплачиваете Vedexx",
        description: "Это позволяет кредитовать клиентов сервиса, предоставляя отсрочки платежей",
        bg_img: "bg_slide_finance.webp",
        href: "12",
        style_options: "margin-left: 50%",
        man_img: "coins.webp",
      },

      {
        id: 4,
        title: "Лучшее предложение из Китая",
        description: "Доставка контейнера",
        bg_img: "bg_slide_china.webp",
        href: "1",
        style_options: null,
        ports: [
          {
            name: "Yantian",
            id: 16
          },
          {
            name: "Ningbo",
            id: 2
          },
          {
            name: "Qingdao",
            id: 8
          },
          {
            name: "Shanghai",
            id: 4
          },
        ],
        rates: [
          {
            place: "Москва",
            price: "417 475",
            place_to_id: 72,
            place_from_id: 2,
          },
          
          {
            place: "Санкт-Петербург",
            price: "391 358",
            place_to_id: 65,
            place_from_id: 2,
          },
          
          {
            place: "Екатеринбург",
            price: "422 736",
            place_to_id: 74,
            place_from_id: 2,
          },
        ]
      },
      
      {
        id: 2,
        title: "Vedexx — вместо брокера",
        description: "Таможенное оформление",
        bg_img: "bg_slide3.png",
        href: "2",
        style_options: "margin-left: 25%",
        man_img: "TO_slide_people.png",
      },
      
      {
        id: 5,
        title: "Лучшее предложение из Индии",
        description: "Контейнерные перевозки",
        bg_img: "bg_slide_india.webp",
        href: "1",
        style_options: null,
        man_img: "",
        ports: [
          {
            name: "Nhava Sheva",
            id: 118
          },
          {
            name: "Mundra",
            id: 39
          },
          {
            name: "Chennai",
            id: 46
          },
          {
            name: "Calcutta",
            id: 4757
          }
        ],
        
        rates: [
          {
            place: "Москва",
            price: "417 475",
            place_to_id: 72,
            place_from_id: 2,
          },
          
          {
            place: "Санкт-Петербург",
            price: "391 358",
            place_to_id: 65,
            place_from_id: 2,
          },
          
          {
            place: "Екатеринбург",
            price: "422 736",
            place_to_id: 74,
            place_from_id: 2,
          },
        ]
      },
    
    ],
    
    videos: [
      {
        id:  7,
        title: "",
        subtitle: "",
        videoUrl: "webinar.mp4",
        poster: "video_cover-wn.webp",
        coverUrl: "video_cover-wn.webp"
      },
      {
        id:  6,
        title: "Vedexx White Night",
        subtitle: "Презентация системы Vedexx",
        videoUrl: "White_night.mp4",
        poster: "video_cover-wn.webp",
        coverUrl: "video_cover-wn.webp"
      },
      {
        id:  1,
        title: "IT-логистика с Vedexx",
        subtitle: "Освободи своё время, логистику оставь на Vedexx",
        videoUrl: "Vedexx_video1.mp4",
        poster: "video_cover1.webp",
        coverUrl: "video_cover1.webp"
      },
      {
        id:  2,
        title: "TransRussia 2023",
        subtitle: "Участие Vedexx на выставке транспортно-логистических услуг",
        videoUrl: "exhibition.mp4",
        poster: "video_cover-exhibition.webp",
        coverUrl: "video_cover-exhibition.webp"
      },
      {
        id:  3,
        title: "IT-логистика с Vedexx",
        subtitle: "Доминируй на рынке перевозок и ВЭД",
        videoUrl: "Vedexx_video2.mp4",
        poster: "video_cover2.webp",
        coverUrl: "video_cover2.webp"
      },
      {
        id:  4,
        title: "Конференция ТПП",
        subtitle: "Презентация Vedexx на Санкт-Петербургской Торгово-Промышленной палате",
        videoUrl: "TPP.mp4",
        poster: "video_cover-tpp.png",
        coverUrl: "video_cover-tpp.png"
      },
      {
        id:  5,
        title: "IT-логистика с Vedexx",
        subtitle: "Что может Vedexx за 5 секунд",
        videoUrl: "Vedexx_video3.mp4",
        poster: "video_cover3.webp",
        coverUrl: "video_cover3.webp"
      },
    ]
  }),
  
  created() {
    this.$_setsetting('routerState', { path: '/Main', query: {}})
    
    bus.$on("show_one_rate", () => {
      this.searchComplexRateClicked = false
      this.searchOneRateClicked = true
      bus.$emit("showRateList", true);
    });
    
    bus.$on("show_complex_rate", () => {
      this.searchOneRateClicked = false
      this.searchComplexRateClicked = true
      bus.$emit("showRateList", true);
    });
    
    bus.$on("hide_rate", () => {
      this.searchOneRateClicked = false
      this.searchComplexRateClicked = false
      bus.$emit("showRateList", false);
    });
  },
  
  computed: {
    isAuth() {
      return !!this.$_getsetting('is_auth');
    },
  },
  
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
    
    if (this.$_getsetting("client_id"))
    {
      this.cId = this.$_getsetting("client_id");
    } else
      this.cId = -99
  },
  
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth
    }
  }
}
</script>

<template>
  <div class="main-page">
    <h1 class="main-page__ttl">
      Единая система организации ВЭД
    </h1>
    <div class="main-page__tabs-container">
      <v-tabs
        v-if="isAuth && windowWidth > 743"
        ref="searchRates"
        id="searchRates"
        v-model="tab"
        class="main-page__tabs g-col-sm-10 g-col-md-12 g-col-lg-12"
        :class="isAuth === true ? '' : 'main-page__tabs-unauth-width'"
        color="none"
        centered
        active-class="main-page__tab-active"
      >
        <v-tabs-slider style="display: none"/>
        
        <v-tab class="main-page__tab g-col-sm-2 g-col-md-2 g-col-lg-2 " v-if="windowWidth > 743">Один маршрут</v-tab>
        <v-tab class="main-page__tab g-col-xs-1" v-else>Маршрут</v-tab>
        <v-tab v-if="isAuth === true" class="main-page__tab main-page__tab-centered g-col-sm-2 g-col-md-3 g-col-lg-2">Несколько маршрутов</v-tab>
<!--        <v-tab class="main-page__tab g-col-sm-2 g-col-md-2 g-col-lg-2 g-col-xs-1">Опасные грузы</v-tab>-->
      </v-tabs>
    </div>
    
    <v-tabs-items v-model="tab" class="main-page__find-panel ">
      <v-tab-item>
        <FindRatePanel
          class="main-page__find-rate"
          :cId="parseInt(cId)"
        />
      </v-tab-item>
      
      <v-tab-item v-if="isAuth === true">
        <FindComplexRatePanel
          class="main-page__find-complex"
        />
      </v-tab-item>
      
      <v-tab-item>
        <FIndDangerRatePanel
          class="main-page__find-rate main-page__find-rate-danger"
          :cId="parseInt(cId)"
        />
      </v-tab-item>
    
    </v-tabs-items>
    
    <div class="main-page__find-panel"  v-if="!isAuth || windowWidth < 744">
    <FindRatePanel
      ref="searchRates"
      class="main-page__find-rate main-page__find-rate-unauth"
      :cId="parseInt(cId)"
    />
    </div>
    
    <InfoSlider
      v-once
      v-if="!searchOneRateClicked && !searchComplexRateClicked"
      :slides="slides"
      class="main-page__slider"
      :class="isAuth ? '' : 'main-page__slider-unauth'"
    />

    <RateListNew
      v-if="searchOneRateClicked"
      class="main-page__rate-list g-col-lg-12"
      :class="isAuth ? '' : 'main-page__rate-list-unauth'"
    />
    
    <RateComplexList
      v-if="searchComplexRateClicked"
      class="main-page__rate-complex-list"
    />
    
    <MainInfoBlock
      v-once
      :videos="videos"
      v-if="!searchOneRateClicked && !searchComplexRateClicked"
      class="main-page__info-block"
    />
    
    <InfoNewsBlock
      v-if="!searchOneRateClicked && !searchComplexRateClicked"
      class="main-page__info-news-block"
    />
  </div>
</template>

<style scoped lang="scss">

.main-page {
  z-index: 100;
  margin-top: 80px;
  max-width: 100%;
  
  @media (max-width: $tablet-width) {
    margin-top: 88px;
  }
  
  &__ttl {
    font-weight: 800;
    font-size: 44px;
    line-height: 60px;
    letter-spacing: 0.01em;
    margin-top: 20px;
    color: #FFFFFF;
    text-align: center;
    position: relative;
    //z-index: 10;
    
    @media (max-width: 1000px) {
      font-size: 30px;
      line-height: 36px;
      text-align: center;
      letter-spacing: 0.01em;
    }
    
    @media (max-width: $mobile-width) {
      margin-top: $size-40;
    }
  }
  
  &__tabs {
    margin: $size-48 auto 0;
    text-align: center;
    justify-content: center;
    width: 100%;
    
    @media (max-width: $tablet-width) {
      margin: $size-24 auto 0;
    }
    
    &-container {
      width: 100% !important;
      justify-content: center;
      display: flex;
      margin: 0 auto;
    }
    
    &-unauth-width {
      width: 100%;
      position: relative;
      justify-content: center;
    }
  }
  
  &__tab {
    color: #FFFFFF !important;
    text-transform: none;
    height: $size-36;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    
    &-centered {
      @media (max-width: 744px) {
        display: none;
      }
    }
  }
  
  &__tab-active {
    background: #F0A030;
    border: 1px solid #FFFFFF;
  }
  
  &__find-panel {
    position: absolute;
    margin-top: $size-20;
    width: calc(100% - 132px);
    z-index: 4;
    left: 50%;
    transform: translateX(-50%);
    
    @media (min-width: 1920px) {
      width: calc(1696px - 224px - 64px);
    }
    
    @media (max-width: $tablet-width) {
      width: calc(100% - 88px);
    }
    
    @media (max-width: $mobile-width) {
      width: calc(100% - 48px);
    }
  }
  
  &__find-rate {
    &-danger {
      @media (max-width: $mobile-width) {
        margin-top: 4px;
      }
    }
    
    &-unauth {
      margin-top: 60px;
      
      @media (max-width: $mobile-width) {
        margin-top: 30px;
      }
    }
  }
  
  &__find-complex {
    @media (max-width: $mobile-width) {
      margin-top: 0;
    }
  }
  
  &__slider {
    position: relative;
    width: 100vw;
    left: -64px;
    margin-top: 100px;
    height: 920px;
    
    @media (min-width: 1920px) {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
      height: auto;
    }
    
    @media (max-width: $tablet-width) {
      left: -32px;
      height: auto;
      margin-top: 100px;
    }
    
    @media (max-width: $mobile-width) {
      left: -16px;
      margin-top: 420px;
    }
    
    &-unauth {
      @media (min-width: $mobile-width) {
        margin-top: 140px;
      }
    }
  }
  
  &__rate-list {
    position: relative;
    margin: 130px auto 0 auto;
    z-index: 3;
    width: 100vw;
    left: -64px;
    max-height: 1200px;

    &-unauth {
      margin-top: 200px;
    }

    @media (min-width: $desktop-width) {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
    }

    @media (max-width: $tablet-width) {
      height: auto;
      left: -32px;
      margin-top: 260px;
      width: calc(100% + 64px);
      z-index: 4;
    }
    
    @media (max-width: $mobile-width) {
      margin-top: 500px;
      left: -16px;
      width: calc(100% + 32px);
    }
  }
  
  &__rate-complex-list {
    position: relative;
    width: 100%;
    margin: 150px auto 0 auto;
    z-index: 3;
    
    @media (max-width: $tablet-width) {
      height: auto;
      left: -32px;
      margin-top: 185px;
      width: calc(100% + 64px);
    }
  }
  
  &__info-block {
    position: relative;
    width: 100%;
    margin-bottom: 10%;
  }
  
  &__info-news-block {
  }
}

.theme--light.v-tabs-items {
  background: none !important;
}

.v-slide-group__content .v-tabs-bar__content {
  display: flex;
  background: red;
}
</style>