<script>
import {bus} from "../../router/bus";

export default {
  name: 'NextPeriodRoute',
  
  props: {
    route: {
      type: [Array, Object]
    }
  },
  
  data() {
    return {
      ratelist: [],
      on_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }
  },
  
  computed: {
    getNextMonth() {
      var D = new Date(this.route.on_date);
      D.setMonth(D.getMonth() + 1);
      return D
    },
    
    getNextPeriodDate() {
      var D = new Date(this.route.on_date);
      D.setMonth(D.getMonth() + 1);
      D.setDate(1)
      return D
    }
  },
  
  mounted() {
    this.getRateList()
    
    bus.$on('get_next_period', () => {
      this.getRateList()
    })
  },
  
  methods: {
    findNextPeriodRoute() {
      this.sortDates(this.ratelist)
      this.$emit('nextRateList', this.ratelist)
      bus.$emit('change_date', this.getNextPeriodDate);
    },
    
    getRateList() {
      let out_date = new Date(this.getNextPeriodDate)
      out_date.setDate(out_date.getDate());
      this.request_data = out_date
      this.$postapi(this.$address + this.$getters.getRateList.uri, {
        method: this.$getters.getRateList.name,
        on_date: out_date,
        place_to: this.route.place_to,
        place_from: this.route.place_from,
        unit_code: this.route.unit_code,
        token: this.$_getsetting('token'),
        client_id: this.$_getsetting('client_id') ? this.$_getsetting('client_id') : -99
      }).then(data => {
        if (data.error == 0) {
          this.ratelist = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },

    sortDates(ratelist) {
      for (let i = 0; i < ratelist.length; i++) {
        if (ratelist[i].sea_start_dates) {
          let dates = ratelist[i].sea_start_dates.split(',')
          ratelist[i].sea_start_dates = dates.sort(function (a, b) {
            return new Date(a) - new Date(b);
          })
        }
      }
    },
  },
}
</script>

<template>
  <div class="next-period-route" v-if="ratelist.length > 0">
    <div class="next-period-route__info">
      <p class="next-period-route__info-title">Рекомендуем ознакомиться со ставками следующего периода</p>
      <v-btn
        dark
        width="100%"
        rounded
        v-ripple
        color="var(--main-orange)"
        outlined
        small
        type="submit"
        @click="findNextPeriodRoute"
      >
        <div class="next-period-route-alt_place_from">
          смотреть на {{getNextMonth.toLocaleString('ru', { month: 'long' })}}
        </div>
      </v-btn>
    </div>
  </div>
</template>


<style scoped lang="scss">
.next-period-route{
  width: 100%;
  min-height: 70px;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #e6eae9;
  padding: 4.93%;
  
  &__info {
    
    &-title {
      font-size: 14px;
      line-height: 18px;
      margin: 2px 0 8px 1.61%;
      text-transform: none;
    }
  }
}

.next-period-route-alt_place_from{
  text-transform: none;
  word-wrap: break-word !important;
}

</style>
