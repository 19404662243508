<script>

export default {
  name: 'Video',
  props: {
    video: {
      type: [Array, Object]
    }
  },
  
  data () {
    return {
      videoPlay: false,
      videoPlayed: false,
    }
  },
  
  computed: {
    windowSize() {
      return document.documentElement.clientWidth;
    },
  },
  
  methods: {
    playPause() {
      if (this.videoPlay == false) {
        document.getElementById("video" + this.video.id).play()
        this.videoPlay = true
        this.videoPlayed = true
        console.log(this.video.id)
      } else {
        document.getElementById("video" + this.video.id).pause()
        this.videoPlay = false
      }
    },
    
    showCover() {
      this.videoPlayed = false
      this.videoPlay = false
      console.log('video ended')
    }
  },
  
  mounted () {
    document.getElementById("video" + this.video.id).addEventListener('ended', this.showCover);
  }
}
</script>

<template>
  <div
    class="slider"
  >
    <div class="slider__controls" @click="playPause">
      <v-btn
        :class="videoPlay == true ? 'slider__controls-play-active' : ''"
        class="slider__controls-play"
        rounded
        height="90px"
        width="90px"
        color="rgba(0, 0, 0, 0.4)"
        elevation="0"
        dark
      >
        <v-icon size="45px" v-if="videoPlay == false">
          mdi-play
        </v-icon>
        <v-icon size="45px" v-else>
          mdi-pause
        </v-icon>
      </v-btn>
    
      <div class="slider__controls-timeline"></div>
    </div>
    
    <video
      class="slider__video"
      :src="`/video/${video.videoUrl}`"
      :id="`video${video.id}`"
      :controls="videoPlay"
      @click="playPause"
    >
    </video>
    
    <div
      class="slider__cover"
      v-if="!videoPlay && !videoPlayed"
      @click="playPause"
      :style="windowSize < 1000 && video.coverUrlMobile ?
       `background-image: url(' /video/${video.coverUrlMobile} ');`
     : `background-image: url(' /video/${video.coverUrl} ');`"
    >
      <div class="slider__cover-text" v-if="windowSize < 1000 && video.id == 7">
        <h2>{{video.titleMobile}}</h2>
        <h3>{{video.subtitleMobile}}</h3>
      </div>
      
      <div class="slider__cover-text" v-else>
        <h2>{{video.title}}</h2>
        <h3>{{video.subtitle}}</h3>
      </div>
    </div>
    
    <div
      v-if="!videoPlay && videoPlayed"
      class="slider__cover"
      style="background: none; height: 90%"
      @click="playPause"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
.slider {
  background: #FFFFFF;
  box-shadow: 1.84706px 0px 14.7765px rgba(0, 0, 0, 0.16);
  border-radius: 3.69412px;
  //padding: 0 25px 40px;
  cursor: pointer;
  transition-duration: 0.3s;
  
  &__video {
    width: 200%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  &__controls {
    position: absolute;
    width: 100%;
    height: 90px;
    //height: 90%;
    display: flex;
    top: calc(50% - 45px);
    //top: 5%;
    z-index: 200;
    
    &-play {
      margin: auto;
      border-radius: 50%;
      height: 90px;
      width: 90px;
      
      &-active {
        display: none;
      }
    }
  }
  
  &__cover {
    position: absolute;
    width: 102%;
    height: 90%;
    object-fit: cover;
    background-size: cover;
    top: 0px;
    left: -6px;
    background-position: center;
    
    &-text {
      left: 15%;
      top: calc(50% - 50px);
      max-width: 410px;
      position: relative;
      
      @media (max-width: $mobile-width) {
        left: $size-36;
        max-width: calc(100% - 72px);
      }
    
      > h2 {
        font-weight: 800;
        font-size: 32px;
        line-height: 43px;
        color: #FFFFFF;
        
        @media (max-width: $mobile-width) {
          font-size: 30px;
        }
      }
      
      > h3 {
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;
        color: #FFFFFF;
      }
    }
  }
}

video {
  width: 100% !important;
}
</style>
