import { render, staticRenderFns } from "./VideoFullWidth.vue?vue&type=template&id=4d9a92e4&scoped=true"
import script from "./VideoFullWidth.vue?vue&type=script&lang=js"
export * from "./VideoFullWidth.vue?vue&type=script&lang=js"
import style0 from "./VideoFullWidth.vue?vue&type=style&index=0&id=4d9a92e4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d9a92e4",
  null
  
)

export default component.exports