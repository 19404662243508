<script>
import RateInfoCircles from "../rate-card-updated/RateInfoCircles.vue";
import RateCardHeader from "./RateCardHeader.vue";
import RateCardDeliveryDates from "./RateCardDeliveryDates.vue";
import RateCardDeliveryPrice from "./RateCardDeliveryPrice.vue";
import { bus } from "../../router/bus";


export default {
  name: "RateCard",

  components: {
    RateInfoCircles,
    RateCardHeader,
    RateCardDeliveryDates,
    RateCardDeliveryPrice
  },

  props: {
    rate: {
      type: Object,
      required: true
    },

    deliveryPlace: {
      type: String,
    },
    
    type: {
      type: String,
      required: false,
      default: () => "usual",
      validator: (value) => ["usual", "recommend"].includes(value),
    }
  },

  data() {
    return {
      isBlockZero: false,
    };
  },

  computed:{
    isAuth() {
      return !!this.$_getsetting('is_auth');
    },
  },

  methods: {
    checkBlockVisibility(block) {
      this.isBlockZero = block.offsetWidth === 0 && block.offsetHeight === 0;
    },

    showLoginForm() {
      bus.$emit("showloginform", 'mix');
    },
    
    showRequestForm(item){
      if (this.isAuth) {
        bus.$emit("show_send_request", { rate: item});
      } else {
        this.showLoginForm()
      }
    },
    
    openRateView(v_key, v_on_date) {
      if (this.isAuth) {
        var queryParams = {
          key: v_key,
          on_date: v_on_date,
          cid: this.$_getsetting("client_id")
        }
        if (this.deliveryPlace != null) {
          queryParams.delivery = this.deliveryPlace
        }
        let routeData = this.$router.resolve({
          path: "/RatePage",
          query: queryParams
        });
        window.open(routeData.href, '_blank');
      } else {
        this.showLoginForm()
      }
    },
  },
}
</script>

<template>
  <div class="new-card">
    
    <RateCardHeader
      :type="type"
      :rate="rate"
      @openRatePage="openRateView(rate.key_orig, rate.on_date)"
    />
    <div class="new-card__delivery-info" :class="{ 'not-auth' : !isAuth }">
      <RateCardDeliveryDates :isAuth="isAuth" :rateAllTIme="rate.all_time" :seaStartDates="rate.sea_start_dates"/>
      <RateCardDeliveryPrice :isAuth="isAuth" :totalPrice="rate.total" :detailInfo="rate.detail"/>
    </div>
    
    <div class="new-card__bottom">
      <RateInfoCircles 
        @block-mounted="checkBlockVisibility"
        :class="isBlockZero ? '' : 'new-card__transit-info-circle'"
        :rate="rate"/>
      <div class="new-card__bottom-btns">
        <button 
          class="new-card__bottom-btn new-card__bottom-btn--blue"
          @click="openRateView(rate.key_orig, rate.on_date)"
          v-ripple>
          Подробнее
        </button>
        <button 
          class="new-card__bottom-btn new-card__bottom-btn--orange" 
          @click="showRequestForm(rate)"
          v-ripple>
          Оформить
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.new-card {
  padding: 24px;
  border-radius: 12px;
  border: 2px solid var(--grey-30);

  @media (max-width: $mobile-width){
    padding: 24px 16px;
    border: unset;
    border-radius: unset;
    border-top: 2px solid var(--grey-30);
    border-bottom: 2px solid var(--grey-30);
  }

  &__delivery-info{
    display: grid;
    align-items: center;
    gap: 16px;
    grid-template-columns: repeat(2, minmax(min-content, max-content));
    margin: 24px 0;

    &.not-auth {
      justify-content: space-between;
      align-content: flex-end;
    }

    @media (max-width: $tablet-width) {
      grid-template-columns: repeat(1, minmax(100%, max-content));
    }

    @media (max-width: $mobile-width) {
      margin: 16px 0;
    }
  }

  &__bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $mobile-width){
      flex-direction: column;
      gap: 16px;
      justify-content: flex-start;
      align-items: flex-start;
    }

    &-btns{
     display: flex;
     gap: 0 8px;

     @media (max-width: $tablet-width){
        margin-left: auto;
     }

     @media (max-width: $mobile-width){
        margin-left: unset;
     }

    }

    &-btn{
      padding: 12px 24px;
      border-radius: 16px;
      border: 1px solid;

      font-size: 14px;
      line-height: 16px;
      font-weight: 600;

      transition: .2s;

      &--blue{
        border-color: var(--grey-50);
        color: var(--blue-50);
      }

      &--orange{
        border-color: var(--orange-70);
        background-color: var(--orange-70);
        color: #FFF;

        @media (max-width: $tablet-width){
          display: none;
        }
      }

      @media (max-width: 425px){
        padding: 12px 39px;
      }
      
     }
    }
}
</style>
