<script>
import {bus} from "../../../router/bus";
export default {
  name: 'ReviewCard',
  props: {
    review: {
      type: [Array, Object]
    }
  },
  
  methods: {
    openFullText() {
      bus.$emit('showfullText', this.review)
    }
  }
}
</script>

<template>
  <div
    class="review"
    @click="openFullText"
  >
    <div class="review__photo" v-if="review.img">
      <img :src="review.img">
    </div>
    <div class="review__photo" v-else>
      <img src="src/assets/img/user.jpg">
    </div>
    <div class="review__open-quote">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 104 76" fill="none">
        <path d="M66.4277 75.8383H88.4277L103.094 45.7411V0.595215H59.0944V45.7411H81.0944M7.76107 75.8383H29.7611L44.4277 45.7411V0.595215H0.427734V45.7411H22.4277L7.76107 75.8383Z" fill="#F0A030"/>
      </svg>
    </div>
    
    <div class="review__text">
      <div class="review__text-desc">
        {{review.text}}
      </div>
    </div>
    
    <div class="review__close-quote">
      <svg class="review__close-quote" xmlns="http://www.w3.org/2000/svg"  width="16" height="9" viewBox="0 0 104 76" fill="none">
        <path d="M66.4277 75.8383H88.4277L103.094 45.7411V0.595215H59.0944V45.7411H81.0944M7.76107 75.8383H29.7611L44.4277 45.7411V0.595215H0.427734V45.7411H22.4277L7.76107 75.8383Z" fill="#F0A030"/>
      </svg>
    </div>
    <div class="review__company">{{review.author_company}}</div>
    <div class="review__author">{{review.author_name}}</div>
  </div>
</template>

<style lang="scss" scoped>
.review {
  background: #FFFFFF;
  box-shadow: 1.84706px 0px 14.7765px rgba(0, 0, 0, 0.16);
  border-radius: 3.69412px;
  padding: 0 $size-24 $size-40;
  cursor: pointer;
  transition-duration: 0.3s;
  height: 296px;
  
  @media (max-width: $mobile-width) {
    width: 300px !important;
    min-width: 320px !important;
    display: flex;
    flex-direction: column;
  }
  
  &:hover {
    transform: translateY(-2px) scale(1.01);
  }
  
  &__photo {
    width: $size-36 * 3;
    left: 0;
    right: 0;
    text-align: center;
    height: 110px;
    margin: auto;
    position: relative;
    top: -55px;
    
    > img {
      filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15));
      border-radius: 50%;
      width: $size-36 * 3;
      height: $size-36 * 3;
    }
  }
  
  &__open-quote {
    position: relative;
    margin-top: -55px;
    margin-left: -5px;
  }
  
  &__close-quote {
    margin-top: -23px;
    margin-left: calc(100% - 10px);
  }
  
  &__text {
    display: inline;
    
    &-desc {
      margin: -15px 10px 0;
      display: block;
      position: relative;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      text-align: center;
      letter-spacing: 0.230882px;
      color: #3E3E3E;
      max-height: 156px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }
    //
    //&:before {
    //  content: '🔗';
    //}
    //
    //&:after {
    //  top: -15px;
    //  content: '🔗';
    //}
  }
  
  &__company {
    font-weight: 700;
    font-size: 20px;
    line-height: 0;
    margin-top: 20px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.230882px;
    color: #000000;
  }
  
  &__author {
    font-weight: 500;
    font-size: 14px;
    line-height: 0;
    margin-top: 24px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.230882px;
    color: #000000;
  }
}
</style>
