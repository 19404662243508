<script>
import {bus} from "@/router/bus";
// import HistoryRateSearch from "./HistoryRateSearch.vue";

export default {
  name: "FindRatePanel.vue",
  
  props:{
    cId: {
      type: Number,
    }
  },
  
  components: {
    // HistoryRateSearch
  },
  
  data() {
    return {
      searchInput: null,
      lang: 'en',
      
      searchType: 'fn_get_ratelist_alarm_json',
      historyData: [],
      historyString: [],
      showHistory: false,
      
      unit_code: [],
      client_id: null,
      clients: [],
      autoUpdate: true,
      place_from: [],
      place_to: [],
      isUpdating: false,
      places_from: [],
      places_to: [],
      unit_codes: [],
      country_from: null,
      alarm_class: [],
      country_to: [],
      countryes_from: [],
      alarm_classes: [],
      countryes_to: [],
      fltr_places_from: [],
      fltr_places_to: [],
      on_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: null,
      menu1: false,
    }
  },
  
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.on_date)
    },
    
    windowSize() {
      return document.documentElement.clientWidth;
    },
  },
  
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000)
      }
    },
    on_date() {
      this.dateFormatted = this.formatDate(this.on_date)
    },
    
    searchInput(str) {
      if (!str) {
        return
      }
      this.checkLang(str)
    }
  },
  
  mounted() {
    this.getreferencedata('lgst_alarm_class')
    this.getreferencedata('lgst_place_from_wg')
    this.getreferencedata('lgst_place_to_wg')
    this.getreferencedata('lgst_cont_types')
    this.getreferencedata('clientvdrefclients')
    this.getSearchParamsHistory()
    
    bus.$on("changealertplacefrom", (data) => {
      this.changePlaceFrom(data)
    });
    
    bus.$on("changealertplaceto", (data) => {
      this.changePlaceTo(data)
    });
  },
  
  methods: {
    checkLang(str) {
      clearTimeout(this._timerId)
      
      if (str.length > 0) {
        if (/[а-я]/i.test(str) == true) {
          this.lang = "ru"
        } else {
          this.lang = "en"
        }
      }
      
      this._timerId = setTimeout(() => {
        this.searchInput = str
      }, 10)
    },
    
    getSearchParamsHistory() {
      this.$postapi(this.$address + this.$getters.getSearchParamsHistory.uri, {
        method: this.$getters.getSearchParamsHistory.name,
        search_type: this.searchType,
        token: this.$_getsetting('token')
      }).then(data => {
        if (data.error == 0) {
          this.historyString =JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
          this.historyData = JSON.parse(this.historyString[0].result)
        }
      })
    },
    
    getreferencedata(referencename) {
      this.$postapi(this.$address + this.$getters.getReferenceData.uri, {
        method: this.$getters.getReferenceData.name,
        reference_name: referencename
      }).then(data => {
        if (data.error == 0) {
          switch (referencename) {
            case 'lgst_place_from_wg':
              this.places_from = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              this.returnplacefromreference()
              break
            case 'lgst_place_to_wg':
              this.places_to = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              this.returnplacetoreference()
              break
            case 'lgst_cont_types':
              this.unit_codes = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              break
            case 'lgst_alarm_class':
              this.alarm_classes = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              break
            case 'clientvdrefclients':
              this.clients = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`));
              break
          }
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    remove(item, id) {
      switch (id) {
        case 0: {
          const index = this.place_from.indexOf(item.id)
          if (index >= 0) this.place_from.splice(index, 1)
        }
          break
        case 1: {
          const index = this.unit_code.indexOf(item.id)
          if (index >= 0) this.unit_code.splice(index, 1)
        }
          break
        case 2: {
          const index = this.place_to.indexOf(item.id)
          if (index >= 0) this.place_to.splice(index, 1)
        }
      }
    },
    
    formatDate(date) {
      if (!date) return null
      
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    
    search() {
      bus.$emit('show_one_rate')
      setTimeout(this.sendRequest, 500);
    },
    
    sendRequest() {
      if (this.dateFormatted === null) {
        this.dateFormatted = this.formatDate(this.on_date)
      }
      bus.$emit("getratealarmlist", {
        place_from: this.place_from.id,
        place_to: this.place_to.id,
        unit_code: this.unit_code.id,
        place_from_name: this.place_from.name,
        place_to_name: this.place_to.name,
        unit_code_name: this.unit_code.name,
        on_date: this.on_date,
        alarm_class: this.alarm_class.join(),
        client_id: (this.cId == -5) ? this.client_id : this.cId,
      });
    },
    
    clickOutsideHistory() {
      this.showHistory = false
    },
    
    returnplacefromreference(){
      this.fltr_places_from = []
      if (this.country_from != null) {
        for (let i = 0; i < this.places_from.length; i++)
          if (this.places_from[i].country_id == this.country_from) {
            this.fltr_places_from.push({id: this.places_from[i].id, name: this.places_from[i].name})
          }
      } else this.fltr_places_from = this.places_from
    },
    
    filterplacefrom(){
      this.returnplacefromreference()
    },
    
    returnplacetoreference(){
      this.fltr_places_to = []
      this.fltr_places_to = this.places_to
    },
    
    filterplaceto(){
      this.returnplacetoreference()
    },
    
    searchFromHistory(index) {
      this.place_from = this.historyData[index].places_from[0]
      this.place_to = this.historyData[index].places_to[0]
      this.unit_code = this.historyData[index].unit_type[0]
      // this.alarm_class.push(String(this.historyData[index].alarm_class[0].id))
      this.changeAlarmClasses(this.historyData[index].alarm_class)
      this.changeUnitCode(this.historyData[index].unit_type[0].id)
      this.showHistory = false
      this.search()
      this.sendRequest()
    },
    
    changePlaceFrom(data) {
      let place = this.places_from.find(item => item.id == data);
      this.place_from = place
    },
    
    changePlaceTo(data) {
      let place = this.places_to.find(item => item.id == data);
      this.place_to = place
    },
    
    changeAlarmClasses(data) {
      this.alarm_class = []
      for (let i = 0; i < data.length; i++) {
        this.alarm_class.push(String(data[i].id))
      }
    },
    
    changeUnitCode(data) {
      let unit = this.unit_codes.find(item => item.id == data);
      this.unit_code = unit
    },
    
  },
}
</script>

<template>
  <v-form
    class="rate-panel"
    @submit.prevent="sendRequest(), search()"
  >
    <v-autocomplete
      height="52px"
      class="rate-panel__input g-col-xs-2"
      v-model="place_from"
      color="var(--main-orange)"
      solo
      clearable
      flat
      return-object
      label="Место отправки"
      :item-text="lang === 'en' ? 'name' : 'alt_name'"
      :disabled="isUpdating"
      :items="places_from"
      :search-input.sync="searchInput"
    >
      
      <template v-slot:item="data" class="rate-panel__input-content">
        <template class="rate-panel__input-content">
          <v-list-item-content>
            <v-list-item-title>{{lang === 'en' ? data.item.name : data.item.alt_name}}
              ({{lang === 'en' ? data.item.alt_name : data.item.name}})</v-list-item-title>
            <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    
    <v-autocomplete
      class="rate-panel__input g-col-xs-2"
      height="52px"
      v-model="place_to"
      :disabled="isUpdating"
      :items="fltr_places_to"
      color="var(--main-orange)"
      solo
      clearable
      flat
      return-object
      label="Место доставки"
      item-text="name"
      @change="filterplaceto"
    >
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    
    <v-autocomplete
      class="rate-panel__input g-col-xs-2"
      height="52px"
      v-model="unit_code"
      :disabled="isUpdating"
      :items="unit_codes"
      solo
      color="var(--main-orange)"
      clearable
      flat
      return-object
      label="Оборудование"
      item-text="name"
    >
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    
    <div class="rate-panel__input  g-col-xs-2">
      <v-autocomplete
        v-model="alarm_class"
        :disabled="isUpdating"
        :items="alarm_classes"
        class="g-col-xs-2"
        color="var(--main-orange)"
        multiple
        solo
        clearable
        flat
        label="Класс опасности"
        item-text="name"
        item-value="id"
        @change="filterplaceto"
      >
        <template v-slot:item="data">
          <template v-if="typeof data.item !== 'object'">
            <v-list-item-content v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-content>
              <v-list-item-title v-html="data.item.name"></v-list-item-title>
              <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </div>
    
    <v-menu
      ref="menu1"
      class="g-col-xs-2"
      v-model="menu1"
      :close-on-content-click="false"
      transition="scale-transition"
      color="var(--main-orange)"
      offset-y
      max-width="290px"
      min-width="auto"
      :left="windowSize < 800"
    >
      
      <template v-slot:activator="{ on, attrs }">
        <!--         <span>Дата готовности груза</span>-->
        <v-text-field
          height="52px"
          class="rate-panel__input g-col-xs-2"
          v-model="dateFormatted"
          prepend-inner-icon="mdi-calendar"
          label="Дата выхода"
          color="var(--main-orange)"
          solo
          flat
          v-bind="attrs"
          @blur="on_date = parseDate(dateFormatted)"
          v-on="on"
        ></v-text-field>
      </template>
      
      <v-date-picker
        first-day-of-week="1"
        color="var(--main-orange)"
        v-model="on_date"
        no-title
        @input="menu1 = false"
      ></v-date-picker>
    </v-menu>
    <!--    </div>-->
    
<!--    <div class="rate-panel__history-container" v-if="historyData.length > 0">-->
<!--      <div class="rate-panel__btn-container" >-->
<!--        <v-btn-->
<!--          class="rate-panel__btn"-->
<!--          :class="{'rate-panel__btn': true}"-->
<!--          active-class="rate-panel__btn"-->
<!--          color="var(&#45;&#45;main-orange)"-->
<!--          depressed-->
<!--          type="submit"-->
<!--        >-->
<!--          Найти варианты-->
<!--        </v-btn>-->
<!--        -->
<!--        <div class="rate-panel__btn-divider"></div>-->
<!--        -->
<!--        <v-btn-->
<!--          class="rate-panel__btn-menu"-->
<!--          :class="{'rate-panel__btn-menu': true}"-->
<!--          active-class="rate-panel__btn-menu"-->
<!--          color="var(&#45;&#45;main-orange)"-->
<!--          depressed-->
<!--          rounded-->
<!--          type="button"-->
<!--          @click="showHistory = !showHistory"-->
<!--        >-->
<!--          <v-icon v-if="showHistory == false">-->
<!--            mdi-menu-down-->
<!--          </v-icon>-->
<!--          -->
<!--          <v-icon v-if="showHistory == true">-->
<!--            mdi-menu-up-->
<!--          </v-icon>-->
<!--        </v-btn>-->
<!--      </div>-->
<!--      -->
<!--      <div-->
<!--        class="rate-panel__history"-->
<!--        v-if="showHistory === true"-->
<!--        v-click-outside="clickOutsideHistory"-->
<!--      >-->
<!--        <HistoryRateSearch-->
<!--          class="rate-panel__history-list"-->
<!--          @search="searchFromHistory($event)"-->
<!--          :histories="historyData"-->
<!--        />-->
<!--      </div>-->
<!--    </div>-->
    
    <v-btn
      class="rate-panel__btn rate-panel__btn-rounded g-col-lg-2 g-col-md-2 g-col-sm-4 g-col-xs-2"
      color="var(--main-orange)"
      dark
      depressed
      rounded
      large
      type="submit"
    >
      <v-icon left>
        mdi-magnify
      </v-icon>
      Найти варианты
    </v-btn>
  </v-form>
</template>

<style scoped lang="scss">
.rate-panel {
  display: flex;
  width: auto;
  
  @media (max-width: $tablet-width) {
    flex-wrap: wrap;
  }
  
  @media (max-width: $mobile-width) {
    flex-direction: column;
  }
  
  &__input {
    height: 52px !important;
    border-radius: 2px;
    width: 20%;
    background: white;
    
    &:not(:last-child) {
      @include right-gap;
    }
    
    @media (max-width: $mobile-width) {
      margin-top: $size-20;
      height: 56px !important;
      width: auto;
    }
    
    @media (max-width: 1200px) {
      margin-top: $size-16;
    }
    
    &-content {
      max-width: 20px !important;
    }
  }
  
  &__multi-select {
    height: 52px !important;
    border-radius: 2px;
    margin: 0 10px 0 0;
    width: 35%;
    background: white;
    
  }
  
  &__mobile {
    display: flex;
    width: 60%;
    
    @media (max-width: 600px) {
      display: flex;
      width: 100%;
      margin-bottom: 8px;
    }
    
    &-input {
      width: 24%;
      
      @media (max-width: 600px) {
        //margin-right: 7px;
        width: auto;
      }
    }
  }
  
  &__history-container {
    width: 210px;
    height: auto;
    
    @media (max-width: 600px) {
      width: 100%;
      margin-top: 10px;
    }
  }
  
  &__btn-container {
    display: flex;
    width: 100%;
  }
  
  &__btn {
    font-size: 14px;
    font-family: 'Manrope', sans-serif;
    height: 52px !important;
    font-weight: 700;
    color: white;
    align-items: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    border-radius: 30px 0 0 30px;
    //border-radius: 30px;
    
    @media (max-width: 1200px) {
      display: block !important;
      margin-top: $size-16;
    }
    
    @media (max-width: $mobile-width) {
      margin-top: $size-24;
      width: 100%;
    }
    
    @media (max-width: 600px) {
      height: 50px !important;
      width: calc(100% - 42px);
    }
    
    &-rounded {
      border-radius: 30px;
      
      @media (max-width: $mobile-width) {
        width: 100%;
      }
    }
  }
  
  &__btn-divider {
    background: #FFFFFF;
    height: 52px;
    width: 2px;
    
    @media (max-width: 600px) {
      height: 50px;
    }
  }
  
  &__btn-menu {
    height: 52px !important;
    color: white;
    min-width: 40px !important;
    align-items: center;
    display: flex;
    border-radius: 0 30px 30px 0;
    padding: 0 !important;
    
    @media (max-width: 600px) {
      height: 50px !important;
    }
  }
  
  
  &__history {
    position: relative;
    z-index: 999;
    margin-top: 5px;
    right: 50%;
    width: 150%;
    display: inline-block;
    
    @media (max-width: 600px) {
      right: 0;
      width: 100%;
    }
    
    &-list {
      width: auto;
    }
  }
}

.v-application--is-ltr .v-messages {
  color: #FFFFFF !important;
}
</style>
