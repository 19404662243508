<script>
import NewsCardShort from "../main-page/news/NewsCardShort";
import {bus} from "../../router/bus";
export default {
  name: 'RateLegend',
  
  components: {
    NewsCardShort
  },
  
  data: () => ({
    newsList: null,
    legend:[
//      {id: 0, name: "Ограничений нет", img: "blue.png", description: "Есть порожнее оборудование."},
//      {id: 1, name: "Требует уточнения", img: "yellow.png", description: "Порожнее оборудование предоставляется по запросу."},
//      {id: 2, name: "Порожнее оборудование отсутствует", img: "red.png", description: "В данном порту выхода отсутсвует порожнее оборудование. Выход не возможен"},
//      {id: 3, name: "Предзаказ", img: "green.png", description: "На судне есть свободные места, доступные для размещения оборудования."},
      {id: 0, name: "Арендованное оборудование", img: "blue.png", description: "Контейнер взят в аренду"},
    ]
  }),
  
  computed: {
    getClientId() {
      return this.$_getsetting('client_id')
    }
  },
  
  mounted() {
    this.getNews()
  },
  
  methods: {
    getNews() {
      this.$postapi(this.$address + this.$getters.getHotNewsList.uri, {
        method: this.$getters.getHotNewsList.name,
        count: 3,
      }).then(data => {
        if (data.error === 0) {
          this.newsList = JSON.parse(data.message)
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
  }
}
</script>

<template>
  <div class="rate-legend">
    <div class="rate-legend-row" v-for="item in legend" :key="item.id" :alt="item.description" :title="item.description">
      <div class="rate-legend-info">
        <div class="rate-legend-img">
          <img :src="item.img"/>
        </div>
        <div class="rate-legend-name">
          {{item.name}}
        </div>
      </div>
    </div>
    <!--div class="rate-legend-column">
      <v-icon class="title-green">mdi-check-circle</v-icon><div class="rate-legend-name">Сервис проверен</div>
    </div-->
    <div class="rate-legend-column">
      <v-icon color="var(--main-orange)">mdi-lightning-bolt</v-icon><div class="rate-legend-name">Горячее предложение</div>
    </div>
    <div class="rate-legend-column">
      <img src="../../assets/img/gondola.png" alt="Отправк в полувагоне" title="Возможна отправка в полувагоне"><div class="rate-legend-name">Возможна отправка в полувагоне</div>
    </div>
    <div class="rate-legend-column">
      <img src="../../assets/img/crane.png" alt="Есть перевалка" title="Есть перевалка"><div class="rate-legend-name">Есть перевалка</div>
    </div>
    <div class="rate-legend-column" v-if="getClientId == 0 || getClientId == -1">
      <img src="../../assets/img/agent.png" class="pl-1 " alt="Агентское предложение" title="Агентское предложение"><div class="rate-legend-name mb-1">Агентское предложение</div>
    </div>
    <v-divider class="my-2 mx-1"/>

    <div class=" rate-legend-news" v-if="newsList != null">
      <a href="NewsList"
         target="_blank" >
        Новости</a>
      <NewsCardShort
        class="rate-legend-news-card"
        v-for="(news, index) in newsList"
        :key="index"
        :news="news"
      />
    </div>
  </div>
</template>

<style scoped>
.title-red{
  color: var(--dark-orange);
}
.title-green{
  color: #2ea700;
}
.rate-legend-column{
  display: flex;
  flex-direction: row;
}

.rate-legend-news {
  text-align: center;
  margin: 15px 0 10px;
  width: 100%;
}

.rate-legend-news-card {
}

.rate-legend-column > img {
  width: 20px;
  margin: 5px 0 0 5px;
  height: auto;
  max-height: 20px;
}

.rate-legend{
  width: 100%;
  min-height: 18vw;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #e6eae9;
  padding: 4.93%;
  background: #FFFFFF ;
}
.rate-legend-row{
  display: flex;
  flex-direction: column;
  padding-bottom: 2px;
  /*margin-bottom: 6px;*/
  /*border-bottom: 1px solid #e6eae9;*/
}
.rate-legend-info{
  display: flex;
  flex-direction: row;
  line-height: 10px;
}
.rate-legend-desc{
  font-size: 8pt;
  width: 100%;
  text-align: justify;
  text-indent: 3.22%;
  font-style: italic;
}
.rate-legend-img{
  width: 8%;
}
.rate-legend-img img{
  width: 100%;
  top: 0;
}
.rate-legend-name{
  width: 80%;
  font-size: 9pt;
  line-height: 15px;
  margin: auto;
}

a {
  text-decoration: none;
  color: black !important;
}
</style>
