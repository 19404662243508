<script>
export default {
  name: 'NewsCard',
  props: {
    news: {
      type: [Array, Object]
    }
  },
  
  data () {
    return {
    }
  },
  
  methods: {
    openNewsPage(){
      let routeData = this.$router.resolve({
          path: "/RatePage",
          query: {
            key: this.price.key_orig,
            on_date: this.on_date,
            cid: this.$_getsetting("client_id")
          }
        });
      window.open(routeData.href, '_blank');
    },
  }
}
</script>

<template>
  <a
    :href="'NewsPage/' + news.id"
    target="_blank" >
    <div
      class="news-card"
    >
      <div class="news-card__title">
        {{news.news_title}}
      </div>
      
      <div class="news-card__date">
        {{news.news_date}}
      </div>
    </div>
  </a>
</template>

<style lang="scss" scoped>
.news-card {
  border-top: 0.5px solid rgba(0, 0, 0, 0.20);
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.20);
  background: #FFFFFF;
  padding: 8px;
  width: 100%;
  
  &__title {
    text-align: left;
    color: var(--neutral-900, #000);
    //font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.775px; /* 125.224% */
    letter-spacing: 0.247px;
    max-height: 30px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
  
  &__date {
    text-align: left;
    color: #A8A8A8;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 9px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.775px; /* 186.384% */
    letter-spacing: 0.247px;
  }
}

a {
  text-decoration: none;
  
  &:after {
    content: none !important;
  }
  
  &:before {
    content: none !important;
  }
}
</style>
