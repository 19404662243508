<script>
export default {
  props: {
    message: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      icon: require('../../assets/img/icons/warning-circle-orange.svg')
    };
  }
}
</script>

<template>
  <div class="notification">
    <img class="notification__icon" :src="icon" alt="warning"/>️
    <span class="notification__text">
      {{ message }}
    </span>
  </div>
</template>

<style lang="scss">
.notification {
  background-color: var(--orange-10);
  border: 1px solid var(--orange-70);
  padding: 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;

  &__icon {
    margin-right: 10px;
  }

  &__text {
    flex-grow: 1;
    display: flex;
    align-items: center;
    font-weight: 500;
  }
}
</style>
