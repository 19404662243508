<script>
import RateCard from "../rate-card-updated/RateCard.vue";
import { bus } from "@/router/bus";

export default {
  name: 'RateRecommendList',
  
  components: {
    RateCard
  },
  
  props: {
    ratelist: {
      type: [Array, Object]
    },
  },
  
  data: () => ({
    rateGroups: {
      deep_sea: [],
      short_sea: [],
      is_not_sea: [],
    },
    
    rateGroupLength: {
      deep_sea: 0,
      short_sea: 0,
      is_not_sea: 0,
    },
    
    groups: [
      {name: "Через Дальний Восток", value: 'short_sea'},
      {name: "Прямое жд", value: 'is_not_sea'},
      {name: "Через СПб, Новороссийск", value: 'deep_sea'},
    ],
  }),
  
  watch: {
    ratelist: function () {
      this.groupByCategory()
    },
    deep: true
  },
  
  mounted() {
    this.groupByCategory()
  },
  
  methods: {
    chooseFastFilter(data) {
      bus.$emit('chooseFastFilter', data)
    },
    
    groupByCategory() {
      this.rateGroups.deep_sea = [];
      this.rateGroups.short_sea = [];
      this.rateGroups.is_not_sea = [];
      this.rateGroupLength.deep_sea = 0;
      this.rateGroupLength.short_sea = 0;
      this.rateGroupLength.is_not_sea = 0;
      
      for (let i = 0; i < this.ratelist.length; i++) {
        if (this.ratelist[i].group_id ) {
          if (this.ratelist[i].group_id == 1) {
            this.rateGroups.short_sea.push(this.ratelist[i])
          }
          if (this.ratelist[i].group_id == 2) {
            this.rateGroups.is_not_sea.push(this.ratelist[i])
          }
          if (this.ratelist[i].group_id == 3) {
            this.rateGroups.deep_sea.push(this.ratelist[i])
          }
        }
      }
      this.sortGroupRates()
      this.countGroupRates()
      this.rateGroups.deep_sea = this.removeDuplicates(this.rateGroups.deep_sea);
      this.rateGroups.is_not_sea = this.removeDuplicates(this.rateGroups.is_not_sea);
      this.rateGroups.short_sea = this.removeDuplicates(this.rateGroups.short_sea);
    },
    
    sortGroupRates() {
      this.rateGroups.short_sea.sort(function (a, b) {
        return b.idx - a.idx
      });
      
      this.rateGroups.is_not_sea.sort(function (a, b) {
        return b.idx - a.idx
      });
      
      this.rateGroups.deep_sea.sort(function (a, b) {
        return b.idx - a.idx
      });
    },
    
    countGroupRates() {
      for (let i = 0; i < this.ratelist.length; i++) {
        if (this.ratelist[i].is_fareast_region == 1 && this.ratelist[i].is_sea == 1){
          this.rateGroupLength.short_sea += 1;
        } else if (this.ratelist[i].is_direct == 1 && this.ratelist[i].is_sea == 0) {
          this.rateGroupLength.is_not_sea  += 1;
        } else {
          this.rateGroupLength.deep_sea += 1;
        }
      }
    },
    
    removeDuplicates(arr) {
      const uniqueRates = [];
      let previousRate;
      
      for (const rate of arr) {
        
        if (!previousRate || !uniqueRates.some(param => param.line_id === rate.line_id)) {
          uniqueRates.push(rate);
          previousRate = rate;
        }
      }
      
      return uniqueRates;
    },
  }
}
</script>

<template>
  <div class="recommend">
    <div
      v-for="(group, index) in groups"
      :key="index"
    >
      <div
        class="recommend__card-group"
        v-if="rateGroups[group.value].length > 0"
      >
        <div class="recommend__card-group__plate">
          <div class="recommend__card-group__plate-label"> {{group.name}}</div>
          <div class="recommend__card-group__plate-count">
            {{rateGroups[group.value].length >= 3 ? 3 : rateGroups[group.value].length}}
            из {{rateGroupLength[group.value]}}
            <span @click="chooseFastFilter(group.value), $emit('closeRecommend')">Смотреть все</span>
          </div>
        </div>
        
        <div class="recommend__card-group__container">
          <RateCard
            v-for="(rate, index) in rateGroups[group.value].slice(0, 3)"
            :key="index"
            :rate="rate"
            type="recommend"
            class="recommend__card-group__container-card"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.recommend {
  border-radius: 8px;
  background: #FFF;
  width: 100%;
  margin: auto;
  height: 100%;
  min-height: 500px;
  
  &__card-group {
    
    &:not(:last-child) {
      margin-bottom: 30px;
    }
    
    &__plate {
      height: $size-32;
      margin-bottom: $size-16;
      display: flex;
      padding: $size-8 $size-40;
      justify-content: space-between;
      width: 100%;
      background: #F5F7FC;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.01em;
      text-align: left;
      
      @media (max-width: $mobile-width) {
        padding: $size-8 $size-24;
        font-size: 12px;
      }
      
      &-count {
        
        > span {
          color: #305CA8;
          margin-left: $size-8;
          text-decoration: underline;
          font-weight: 600;
          cursor: pointer;
          transition: all 0.3s;
          
          @media (max-width: $mobile-width) {
            margin-left: $size-4;
          }
          
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    
    &__container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      
      @media (max-width: $mobile-width) {
        background: #F8F8F8;
      }
      
      &-card {
        margin: 0 auto $size-12 ;
        width: calc(100% - 32px);
        
        &:last-child {
          margin-bottom: $size-12;
        }
        
        @media (max-width: $mobile-width) {
          width: 100%;
        }
      }
    }
  }
}
</style>
